import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { json, useParams } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    CircularProgress,
} from "@mui/material";
import {
    CREAR_AMBIENTE,
    EDITAR_AMBIENTE,
    ELIMINAR_AMBIENTE,
} from "../../Mutations/ambientes";
import { GET_AMBIENTES } from "../../Queries/ambientes";
import LayoutNBItems from "./LayoutNBItems";
import { stringify } from "postcss/lib/postcss";

function Ambiente({ idApartamento }) {
    const { id } = useParams();
    const apartmentId = idApartamento || id;

    const {
        data,
        loading: queryLoading,
        error: queryError,
        refetch,
    } = useQuery(GET_AMBIENTES, {
        variables: { id: apartmentId },
    });

    const [crearAmbiente, { loading: mutationLoading, error: mutationError }] =
        useMutation(CREAR_AMBIENTE, {
            onCompleted: () => {
                refetch();
                closeModal();
            },
        });

    const [editarAmbiente] = useMutation(EDITAR_AMBIENTE, {
        onCompleted: () => {
            refetch();
            closeModal();
        },
    });

    const [eliminarAmbiente] = useMutation(ELIMINAR_AMBIENTE, {
        onCompleted: () => {
            refetch();
        },
    });

    const [ambientesAux, setAmbientesAux] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [ambiente, setAmbiente] = useState("");
    const [detalles, setDetalles] = useState("");
    const [editingId, setEditingId] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingId) {
            editarAmbiente({
                variables: {
                    ambienteId: editingId,
                    inputAmbiente: { nombre: ambiente, detalles },
                },
            });
        } else {
            crearAmbiente({
                variables: {
                    apartamentoId: apartmentId,
                    inputAmbiente: { nombre: ambiente, detalles },
                },
            });
        }
        setAmbiente("");
        setDetalles("");
        setEditingId(null);
    };

    const handleEdit = (ambiente) => {
        setAmbiente(ambiente.nombre);
        setDetalles(ambiente.detalles);
        setEditingId(ambiente._id);
        openModal();
    };

    const handleDelete = (id) => {
        eliminarAmbiente({ variables: { ambienteId: id } });
    };

    useEffect(() => {
        if (data && data.ambientes) {
            setAmbientesAux(data.ambientes);
        }
    }, [data]);

    useEffect(() => {
        if (apartmentId) {
            refetch();
        }
    }, [apartmentId, refetch]);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setAmbiente("");
        setDetalles("");
        setEditingId(null);
    };


    const handleOrderChange = (index, direction) => {
        console.log(ambientesAux);
         setAmbientesAux((prevAmbientes) => {
             const ambientesActualizados = [...prevAmbientes]; // Crea una copia del arreglo
        //     console.log(ambientesActualizados);
            if (direction === 'up' && index > 0) {
                // Intercambiar posición con la tarea anterior
                [ambientesActualizados[index - 1], ambientesActualizados[index]] = [
                    ambientesActualizados[index],
                    ambientesActualizados[index - 1],
                ];
            } else if (direction === 'down' && index < prevAmbientes.length - 1) {
                // Intercambiar posición con la tarea siguiente
                [ambientesActualizados[index], ambientesActualizados[index + 1]] = [
                    ambientesActualizados[index + 1],
                    ambientesActualizados[index],
                ];
            };

        //     // console.log(ambientesActualizados)

        //     // // Actualizar la base de datos con los nuevos valores de orden
            ambientesActualizados.forEach((ambiente, newIndex) => {
                editarAmbiente({
                    variables: {
                        ambienteId: ambiente._id,
                        inputAmbiente: { nombre: ambiente.nombre, detalles: ambiente.detalles, orden: newIndex },
                    },
                });
            });
            
        return ambientesActualizados;
        });
    };
            if (queryLoading) return <CircularProgress />;
            if (queryError)
                return <Typography color="error">Error: {queryError.message}</Typography>;

            return (
                <LayoutNBItems>
                    <div>
                        <Typography variant="h4" gutterBottom>
                            Ambientes
                        </Typography>
                        <Typography variant="body1">
                            Administra los ambientes de la propiedad.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => openModal()}
                            style={{ margin: "10px 0" }}
                        >
                            Nuevo
                        </Button>
                    </div>
                    {ambientesAux?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ambiente</TableCell>
                                        <TableCell>Detalles</TableCell>
                                        <TableCell>Orden</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ambientesAux.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.nombre}</TableCell>
                                            <TableCell>{item.detalles}</TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => handleOrderChange(index, "up")}
                                                    disabled={index === 0}
                                                >
                                                    ⬆
                                                </Button>
                                                <Button
                                                    onClick={() => handleOrderChange(index, "down")}
                                                    // disabled={index === ambientesAux?.length - 1}
                                                >
                                                    ⬇
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => handleEdit(item)} color="primary">
                                                    Editar
                                                </Button>
                                                <Button
                                                    onClick={() => handleDelete(item._id)}
                                                    color="secondary"
                                                >
                                                    Eliminar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography>No hay ambientes creados</Typography>
                    )}

                    <Dialog open={isModalOpen} onClose={closeModal}>
                        <DialogTitle>
                            {editingId ? "Editar Ambiente" : "Crear Ambiente"}
                        </DialogTitle>
                        <DialogContent>
                            {mutationError && (
                                <Typography color="error">{mutationError.message}</Typography>
                            )}
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Ambiente"
                                fullWidth
                                value={ambiente}
                                onChange={(e) => setAmbiente(e.target.value)}
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                label="Detalles"
                                fullWidth
                                value={detalles}
                                onChange={(e) => setDetalles(e.target.value)}
                                variant="outlined"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeModal} color="secondary">
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                disabled={mutationLoading}
                            >
                                {editingId ? "Actualizar" : "Guardar"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </LayoutNBItems>
            );
        }
export default Ambiente;
